import { Inject, Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import {
  ApiResponse,
  COUNTRY_SLUG_PARAM,
  Event,
  House,
  HouseStats,
  PageData,
  ReservationRoom,
  ROLES,
  User,
} from '@rhbnb-nx-ws/domain';
import { AbstractDataService } from '../util';

import { Moment } from 'moment';
import { API_BASE_URL } from '@rhbnb-nx-ws/global-tokens';
import { MomentService } from './moment.service';
import { environment } from '../../../../../apps/rhbnb-admin/src/environments/environment';

const CACHE_TTL = 15 * 60 * 1000; // 15 mins

@Injectable({
  providedIn: 'root',
})
export class HouseApiService extends AbstractDataService<House> {

  constructor(
    public http: HttpClient,
    @Inject(API_BASE_URL) public apiURL: string,
    private momentService: MomentService
  ) {
    super(http, 'houses', apiURL);
    this.cacheKeys = [];
  }

  add(entity: House): Observable<ApiResponse<House>> {
    return super.add(entity).pipe();
  }

  update(entity: House): Observable<ApiResponse<House>> {
    // Transform house entities to ids
    entity.services = (entity.services as any[])?.map((s) =>
      typeof s === 'object' && s !== null ? s?.id : s
    );

    entity.facilities = (entity.facilities as any[])?.map((f) =>
      typeof f === 'object' && f !== null ? f?.id : f
    );

    entity.destination =
      typeof entity.destination === 'object' && entity?.destination !== null
        ? entity.destination?.id
        : entity.destination;

    entity.type =
      typeof entity?.type === 'object' && entity?.type !== null
        ? entity.type?.id
        : entity.type;

    entity.host =
      typeof entity?.host === 'object' && entity?.host !== null
        ? entity?.host?.id
        : entity?.host;

    return super.update(entity);
  }

  patch(entity: House): Observable<ApiResponse<House>> {
    return super.patch(entity);
  }

  getOne(id: string, withObjects = false): Observable<ApiResponse<House>> {
    return super.getOne(id).pipe(
      map((h) => {
        h.data.location = h?.data?.location?.map((x: any) => parseFloat(x));

        if (!withObjects) {
          h.data.destination =
            typeof h?.data?.destination === 'object'
              ? (h?.data?.destination as any)?.id
              : h?.data?.destination;
          h.data.type =
            typeof h?.data?.type === 'object'
              ? (h?.data?.type as any)?.id
              : h?.data?.type;
        }

        return h;
      })
    );
  }

  getOneRaw(id: string): Observable<ApiResponse<House>> {
    return super.getOne(id);
  }

  getWithRoomEvents(
    id: string,
    start?: Moment,
    end?: Moment
  ): Observable<ApiResponse<House>> {
    let q = '?';

    if (start && end) {
      q += `checkIn=${start.startOf('day').format('YYYY-MM-DD')}`;
      q += `&checkOut=${end.startOf('day').format('YYYY-MM-DD')}`;
    }

    return this.http.get<ApiResponse<House>>(
      `${this?.apiURL}/${this.endpointName}/${id}/with-events${q}`,
    ).pipe(
      map((res) => {
        res.data.rooms = res?.data?.rooms?.map((r: ReservationRoom) => {
          return {
            ...r,
            events: r?.events?.map((e) => {
              return {
                ...e,
                start: this.momentService
                  .getMomentWithLocalOffset(e.start)
                  .format(),
                end: this.momentService.getTomorrowStartOfDayWithLocalTz(
                  this.momentService.getMomentWithLocalOffset(e.end).format()
                ),
              };
            }),
          };
        });

        return res;
      }),
      catchError((error) => observableThrowError(error))
    );
  }

  getRoomEvents(
    id: string,
    start?: Moment,
    end?: Moment
  ): Observable<ApiResponse<{ [key: string]: Event[] }>> {
    let q = '';

    if (start && end) {
      q += `?checkIn=${start.startOf('day').format('YYYY-MM-DD')}`;
      q += `&checkOut=${end.startOf('day').format('YYYY-MM-DD')}`;
    }

    return this.http.get<ApiResponse<{ [key: string]: Event[] }>>(
      `${this?.apiURL}/${this.endpointName}/${id}/room-events${q}`,
    ).pipe(
      map((res) => {
        const roomEvents = res.data;

        Object.keys(roomEvents).forEach((roomKey) => {
          res.data[roomKey] = res.data[roomKey].map((e) => ({
            ...e,
            start: this.momentService
              .getMomentWithLocalOffset(e.start)
              .format(),
            end: this.momentService.getTomorrowStartOfDayWithLocalTz(
              this.momentService.getMomentWithLocalOffset(e.end).format()
            ),
          }));
        });

        return res;
      }),
      catchError((error) => observableThrowError(error))
    );
  }

  paginate(
    q: string,
    page: string,
    limit: string,
    sort: string,
    order: string,
    extraParams?: HttpParams,
    currentUser?: User
  ): Observable<ApiResponse<PageData<House>>> {
    let source$ = super.getMeList(q, page, limit, sort, order);
    let params = `${q}_${page}_${limit}_${sort}_${order}`;

    if (currentUser?.hasRole(ROLES.ROLE_ADMIN)) {
      source$ = super.paginate(q, page, limit, sort, order, extraParams);
      params = `${q}_${page}_${limit}_${sort}_${order}_${extraParams?.toString()}`;
    }

    return source$;
  }

  paginateByHost(
    id: string | number,
    page: string,
    limit: string
  ): Observable<ApiResponse<PageData<House>>> {
    const params: HttpParams = this.buildParams(page, limit);

    return this.http
      .get<ApiResponse<PageData<House>>>(
        `${this.apiURL}/${this.endpointName}/by-host/${id}`,
        { ...{ params }, ...{ observe: 'response' } }
      )
      .pipe(
        map((res: HttpResponse<ApiResponse<PageData<House>>>) => {
          return {
            data: {
              data: res.body.data,
              total: res.headers.get('Page-Total'),
            } as any,
            success: res.body.success,
          } as ApiResponse<PageData<House>>;
        }),
        catchError((error) => observableThrowError(error))
      );
  }

  searchHouse({
    page,
    limit,
    destination,
    province,
    checkIn,
    checkOut,
    rooms,
    guests,
    facilities,
    within,
    country,
  }: {
    page?: string;
    limit?: string;
    destination?: string | number;
    province?: string | number;
    checkIn?: string;
    checkOut?: string;
    rooms?: number;
    guests?: number;
    facilities?: (string | number)[];
    within?: number[][];
    country?: string;
  }): Observable<ApiResponse<PageData<House>>> {
    let params = new HttpParams();

    if (page) {
      params = params.set('_page', page);
    }

    if (limit) {
      params = params.set('_limit', limit);
    }

    if (destination) {
      params = params.set('destination', `${destination}`);
    }

    if (province) {
      params = params.set('province', `${province}`);
      params = params.delete('destination');
    }

    if (checkIn) {
      params = params.set('checkIn', checkIn);
    }

    if (checkOut) {
      params = params.set('checkOut', checkOut);
    }

    if (rooms) {
      params = params.set('rooms', rooms.toString());
    }

    if (guests) {
      params = params.set('guests', guests.toString());
    }

    if (facilities) {
      for (const f of facilities) {
        if (String(f).trim().length > 0) {
          params = params.append('facilities[]', `${f}`);
        }
      }
    }

    if (within) {
      params = params.append('within', JSON.stringify(within));
    }

    const source$ = this.http.get<ApiResponse<PageData<House>>>(
      `${this?.apiURL}/${this.endpointName}/search`,
      {
        ...{ params },
        ...{ observe: 'response' },
      }
    );

    return source$.pipe(
      map((res: HttpResponse<ApiResponse<PageData<House>>>) => {
        return {
          data: {
            data: res?.body?.data,
            total: res?.headers?.get('Page-Total'),
          } as any,
          success: res?.body?.success,
        } as ApiResponse<PageData<House>>;
      }),
      catchError((error) => observableThrowError(error))
    );
  }

  getWithoutHostManagers(): Observable<ApiResponse<House[]>> {
    return this.http
      .get<ApiResponse<House[]>>(
        `${environment.apiURL}/${this.endpointName}/without-hostmanager`,
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  stats(id: string): Observable<ApiResponse<HouseStats>> {
    return this.http
      .get<ApiResponse<HouseStats>>(
        `${environment.apiURL}/${this.endpointName}/stats/${id}`,
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  meStats(): Observable<ApiResponse<HouseStats>> {
    return this.http
      .get<ApiResponse<HouseStats>>(
        `${environment.apiURL}/${this.endpointName}/stats/me`
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  getMeList(
    q?: string,
    page?: string,
    limit?: string,
    sort?: string,
    order?: string,
    filter?: Record<string, any>
  ): Observable<ApiResponse<PageData<House>>> {
    const source$ = super.getMeList(q, page, limit, sort, order, filter);
    return source$;
  }

  rankedHouses(countrySlug = null): Observable<ApiResponse<House[]>> {
    let headers = {} as any;

    if (countrySlug) {
      headers = {
        ...headers,
        [COUNTRY_SLUG_PARAM]: countrySlug
      }
    }

    return this.http.get<ApiResponse<House[]>>(
      `${this?.apiURL}/${this.endpointName}/rank`,
      { headers }
    ).pipe(catchError((error) => observableThrowError(error)));
  }

  suggested(id: string): Observable<ApiResponse<House[]>> {
    const source$ = this.http.get<ApiResponse<House[]>>(
      `${this?.apiURL}/${this.endpointName}/suggested/${id}`,
    );

    return source$
      .pipe(catchError((error) => observableThrowError(error)));
  }

  rank(id: string, value: number): Observable<ApiResponse<House>> {
    return this.http
      .put<ApiResponse<House>>(
        `${this?.apiURL}/${this.endpointName}/${id}/rank`,
        { ranking: value }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  commit(id: string, value: number): Observable<ApiResponse<House>> {
    return this.http
      .put<ApiResponse<House>>(
        `${this?.apiURL}/${this.endpointName}/${id}/commission`,
        { commission: value }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }

  toggleActive(id: string, value: boolean): Observable<ApiResponse<House>> {
    return this.http
      .put<ApiResponse<House>>(
        `${this?.apiURL}/${this.endpointName}/${id}/toggle-active`,
        { active: value }
      )
      .pipe(catchError((error) => observableThrowError(error)));
  }
}
